import { useI18n } from "vue-i18n";

const columns = [
  {
    field: "menu",
    header: "Accès",
  },
  {
    field: "create",
    header: "Créer",
  },
  {
    field: "read",
    header: "Lecture",
  },
  {
    field: "update",
    header: "Modification",
  },
  {
    field: "delete",
    header: "Suppression",
  },
];

export const useRoleColumns = () => {
  const { t } = useI18n();

  const accessText = (allowed: boolean | string) => {
    return typeof allowed === "boolean"
      ? t(allowed ? "yes" : "no")
      : t(`workspaceLayout.${allowed}`);
  };

  return {
    columns,
    accessText,
  };
};
